* {
  margin: 0;
  padding: 0;
}

body {
  background-color: #f5f6fa;
}

.app__dropdown {
  background-color: white;
}

.app {
  display: flex;
  justify-content: space-evenly;
  background-color: #f5f6fa;
  padding: 1rem;
}

.right_column {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-left: 1rem;
}

.right_column .MuiCardContent-root {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.app__graph {
  display: flex !important;
  align-self: stretch !important;
  flex-grow: 1 !important;
}

.app__header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
  justify-content: space-between;
}

.app__header > h1 {
  color: #fc3c3c;
  font-size: 2rem;
}

.app__stats {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between !important;
  margin-top: 1rem;
}

.left_column {
  flex: 0.9;
}

.app__information {
  display: flex !important;
  flex-direction: column;
}

.app__information > h3 {
  color: #6a5d5d;
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app__information > h3:last-of-type {
  margin-top: .5rem;
}

.app__graph_title {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
  
  .right_column {
    margin-top: 1rem;
    margin-left: 0;
  }
}
