.table {
  margin-top: 1rem;
  overflow: scroll;
  overflow-x: hidden;
  height: 814px;
}

.table tr {
  display: flex;
  justify-content: space-between;
}

.table td {
  padding: .5rem;
}

.table tr:nth-of-type(odd) {
  background-color: #f3f2f8;
}